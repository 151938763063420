@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.slides {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.slide {
  width: 100%;
  display: grid;
  gap: 24px;
  padding: 0 5px;
}

.slide[aria-current="false"] {
  display: none;
}

.imageContainer {
  max-width: 100%;
  max-height: 288px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carouselNavigator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  margin-top: auto;
}

.buttonContainer {
  border: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.buttonContainer > button {
  width: 100%;
}

.dotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  border-color: var.$hxBlue1;
  margin: 5px;
  border-style: solid;
  border-width: 1px;
}

.dot[aria-current="true"] {
  background-color: var.$hxBlue1;
}
