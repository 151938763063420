@use '/src/style/variables.scss' as var;

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  position: relative;
}

.link {
  padding: 30px 20px 20px 20px;
  text-decoration: none;
  color: var.$warmGray6;
  align-items: center;
  display: flex;
  cursor: pointer;
  
  &:hover {
    color: var.$warmGray3;
  }
}

.linkActive {
  padding: 30px 20px 20px 20px;
  color: var.$black;
  border-bottom: 4px solid;
  border-bottom-color: var.$hxBlue1;

  &:hover {
    color: var.$warmGray6;
  }
}