@use '/src/style/variables.scss' as var;

// Highlights
.highlights {
  width: 100%;

  .tabContainer {
    flex: 6;
  }
}

@media (max-width: 960px) {
  .highlights {
    height: 100%;
  }
}

// InfoList
.detailsRoot {
  display: flex;
  flex-direction: row;
}

.excursionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid var.$warmGray2;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 16px;
}

.includedCntr {
  display: flex;
  flex-direction: column;
}

.contentCntr {
  padding: 4px 30px;

  @media (max-width: 600px) {
    padding: 4px 0;
  }
}

.right {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.nextTo {
  color: var.$hxBlue1 !important;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.infoListButtonsCntr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
}

.infoListButtonRightCntr {
  display: flex;
  justify-content: flex-end;
}

// InfoListItem
.infoListItem {
  height: 50px;
  border: none!important;
  border-bottom: 1px solid var.$warmGray1!important;
}