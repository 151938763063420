@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.carousel {
  margin: auto;
  display: grid;
  gap: 20px;
}

@include mq.mobile {
  .carousel {
      width: 512px;
  }
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.5rem;
  background-color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  color: var.$hxBlue1;
  display: flex;
  gap: 0.5rem;
}