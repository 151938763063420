@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

/* Styles.module.css */

.summary {
  list-style: none;
  cursor: pointer;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 24px;
}

.summary.hasTwoColumns {
  justify-content: space-between;
}

.summary::-webkit-details-marker {
  display: none;
}

.details {
  background-color: var.$white;
  border: 1px solid var.$warmGray2;
}

.details[open]:hover {
  border-color: var.$hxBlue1;
}

.viewButton {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  gap: 4px;
}

.title {
  font-size: 0.75rem !important;
}

.titleIcon {
  font-size: 0.75rem !important;
}

.titleIcon:hover {
  border-bottom: 1px solid var.$warmGray2
}

.textRows {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 24px!important;
}

.textRows > span:first-child {
  width: 100%;
}

.classNameSummary {
  margin-top: -15px;
}
