.bold {
  font-weight: bold;
}

.spaceUnder {
  margin-bottom: 8px;
}

.customErrorWrapper p {
  font-size: 14px;
}
