@use '/src/style/variables.scss' as var;

// Tab
.tabContainer {
  width: 100%;

  .separatorLine {
    display: none;
  }

  @media (max-width: 960px) {
    .separatorLine {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid;
      border-top-color: var.$warmGray2;
      padding: 0;
    }
  }

  @media (min-width: 961px) {
    .separatorLine {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid;
      border-top-color: var.$warmGray2;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }
}

// TabHeader
.tabHeaderContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  .scrollX {
    overflow-x: hidden;
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }

  .headerContentContainer {
    width: 100%;
    max-width: var.$desktopContainerWidth;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
  }

  @media (max-width: 960px) {
    .tabHeaderContainer {
      border-bottom-width: 0;
      justify-content: initial;
      overflow-y: hidden;
      display: block;
      white-space: normal;
    }

    .scrollX {
      overflow-x: scroll;
    }

    .tabHeaderItemActive,
    .tabHeaderItem {
      display: inline-block;
    }
  }

  @media (max-width: 800px) {
    .headerContainer {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .tabHeaderItemActive {
    border-style: none;
    border-bottom: 3px solid var.$hxBlue1;
    color: var.$hxBlue1;
    height: 100%;
    margin-right: 35px;
    cursor: pointer;
    outline: none;
    padding-bottom: 18px;
    background-color: transparent;
  }

  .tabHeaderItemActive:hover {
    color: var.$warmGray5;
  }

  .tabHeaderItem {
    color: var.$warmGray6;
    height: 100%;
    margin-right: 35px;
    cursor: pointer;
    outline: none;
    padding-bottom: 18px;
    border-style: none;
    background-color: transparent;
  }

  .tabHeaderItem:hover {
    color: var.$warmGray5;
  }
}